import { get, derived } from 'svelte/store'

import { deepSet, isBlank } from '@/util/methods'

export const dedup = (store, initial, eq = (a, b) => a === b) => {
  let prev
  return derived(
    store,
    ($v, set) => {
      if (eq($v, prev)) {
        return
      }
      prev = $v
      set($v)
    },
    initial,
  )
}

// Given another store, create a new store which manipulates part of it.
// Unlike `derived`, it is writable.
// get: ($parentStoreValue) => calculatedValue
// set: ($parentStoreValue, newValue) => newParentStoreValue
export const wrappedStore = (store, { get, set }) => {
  return {
    subscribe: (f) => derived(store, get).subscribe(f),
    set: (v) => store.update(($obj) => set($obj, v)),
    update: (f) => store.update(($obj) => set($obj, f(get($obj)))),
  }
}

// a derived store which subscribes to a store of stores and returns
// the values for all of them. mapfn allows you to transform the value
// of parent into an array of stores.
export const flatMap = (parent, mapfn = (v) => v) => {
  return {
    subscribe: (f) => {
      let childUnsub = () => {} // initialize to no-op
      const parentUnsub = parent.subscribe((parentVal) => {
        const child = derived(mapfn(parentVal), (values) => values)
        childUnsub()
        childUnsub = child.subscribe(f)
      })
      return () => {
        childUnsub()
        parentUnsub()
      }
    },
  }
}

export const field = (store, name) => {
  const der = derived(store, ($obj) => $obj[name])
  return {
    subscribe: (f) => der.subscribe(f),
    set: (v) => update(store, ($obj) => ({ ...$obj, [name]: v })),
  }
}

export const update = (store, f) => {
  if (typeof store.update === 'function') {
    return store.update(f)
  }

  store.set(f(get(store)))
}

export const pathToName = (path) => {
  const sub = path.slice(1).map((v) => `[${v}]`)
  return `${path[0]}${sub.join('')}`
}

export const nameToPath = (name) => {
  const match = name.match(/^([^[]+)(.*)/)
  if (!match) return null
  const path1 = match[1]
  const rem = match[2]
  const brackets = rem.match(/(\[[^\]]+\])/g) ?? []
  return [path1, ...brackets.map((v) => v.substring(1, v.length - 1))]
}

export const mapToObject = (map, transform = (v) => v) => {
  let obj = {}
  for (const [name, val] of map.entries()) {
    const path = nameToPath(name)
    obj = { ...obj, ...deepSet(obj, path, transform(val)) }
  }
  return obj
}

export const flattenDeepObject = (obj, parentKeys = []) => {
  let agg = {}
  for (const [k, v] of Object.entries(obj)) {
    if (!!v && typeof v === 'object' && !Array.isArray(v)) {
      agg = { ...agg, ...flattenDeepObject(v, [...parentKeys, k]) }
    } else {
      agg[pathToName([...parentKeys, k])] = v
    }
  }
  return agg
}

export const validateField = ($field) => {
  if (!$field.validators || $field.validators.size === 0) return $field

  $field.validationError = null
  for (const validator of $field.validators.values()) {
    $field.validationError = $field.validationError || validator($field)
  }
  return $field
}
export const fieldIsComplete = (field) => {
  // NOTE: we run validators but we don't save the result. But we need to
  // do this to ensure required fields are filled. We need to simulate
  // touching the field so required validators match.
  return isBlank(fieldError(validateField({ ...field, touched: true })))
}

export const fieldError = ($field) =>
  $field.customError ?? $field.validationError ?? null
