import { writable, get } from 'svelte/store'
import { wrappedStore } from './util'

function createFieldTransformer(transform) {
  return ($oldMap, $newMap) => {
    for (const [key, val] of $newMap.entries()) {
      $newMap.set(key, transform($oldMap.get(key), val))
    }
    return $newMap
  }
}

// A Store of a Map which allows access to a store on
// a per-key level.
// transform is a function that is called on every set
// with old and new values to apply logic to each key when it is written
export default class MapStore {
  constructor(data = new Map(), transform = null) {
    this._transform = transform
      ? createFieldTransformer(transform)
      : (_, n) => n
    this._store = writable(this._transform(new Map(), data))
  }

  subscribe(f) {
    return this._store.subscribe(f)
  }

  set(val) {
    return this._store.set(this._transform(get(this._store), new Map(val)))
  }

  update(f) {
    return this._store.update((v) => this._transform(v, f(new Map(v))))
  }

  key(key) {
    return wrappedStore(this, {
      get: ($map) => $map.get(key),
      set: ($map, v) => $map.set(key, v),
    })
  }
}
